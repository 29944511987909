var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styles from "./LoadableImage.module.css";
import cn from "classnames";
import useOnScreen from "../../../hooks/lazy-loading/useOnScreen/useOnScreen";
var LoadableImage = function (props) {
    var _a, _b;
    var src = props.src, _c = props.alt, alt = _c === void 0 ? "" : _c, _d = props.onLoad, onLoad = _d === void 0 ? function () { } : _d;
    var _e = React.useState(false), isLoaded = _e[0], setIsLoaded = _e[1];
    var imageRef = React.useRef(null);
    var containerRef = React.useRef(null);
    var isVisible = useOnScreen(containerRef);
    React.useEffect(function () {
        if (!isVisible || isLoaded) {
            return;
        }
        if (imageRef.current) {
            imageRef.current.onload = function () {
                setIsLoaded(true);
                onLoad();
            };
        }
    }, [isVisible, onLoad, isLoaded]);
    return (_jsx("div", __assign({ ref: containerRef, className: cn(styles.container, (_a = {},
            _a[styles.containerLoaded] = isLoaded,
            _a)) }, { children: (isVisible || isLoaded) && (_jsx("img", { ref: imageRef, className: cn(styles.image, (_b = {},
                _b[styles.imageLoaded] = isLoaded,
                _b)), src: src, alt: alt })) })));
};
export default LoadableImage;
